/*  ------------------- SCROLL  ---------------------*/

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #562B7F;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px #cda8f0; */
  margin: 5px 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cda8f0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b484e0;
}


@media screen and (min-width: 1800px) {
  .table-option {
    margin-top: -140px!important;
  }
  .table-option-expanded {
    margin-top: -130px!important;
  }
}

@media screen and (max-width: 1800px) {
  .table-option {
    margin-top: -170px!important;
  }
  .table-option-expanded {
    margin-top: -130px!important;
  }
}


.MuiToolbar-root{
  height: 70px;
}